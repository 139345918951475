.loading{
    position: relative;
    left: 40vw;
    top: 18vh;
    background-color: transparent;
    color: transparent;
    width: 20%;
    height: 20%;
  }

.main-content{
    
    position: relative;
    white-space: pre-line;
    margin-left: 5vw;
    margin-top: 2vh;
    padding: 20px;
    color: white;
    background-color: #181717;
    border-radius: 8px;
    width: 40vw;
    height: auto;
    font-size: 18px;
    
    
}

.selected{
    color: #8FFF00;
}
.main-body{
    display: flex;
    justify-content: end;
    
}
.fixed{
    position: fixed;
}
.not-fixed{
    position: relative;
}
.contents{

    top: -2vh;
    left: 6vw;
    width: 20vw;
    color: white;
    height: auto;
}
.contents > h1{
    font-family: abhar-libre;
    font-weight: 500;
    font-size: 2.5vw;
}
.subtitle{
    font-family: roboto;
    font-size: large;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    position: relative;
    left: 2vw;
    margin-top: 15px;
    cursor: pointer;
}
.stitle{
    position: relative;
    width: 15vw;
    left: 1vw;
    top: -0.5vh;
    margin: 0.25vh;
}

.popup-container{
    display: flex;

}
.popup-button{
    background-color: #6CBB09;
    margin: 0.25vw;
    padding: 0.25vw;
    padding-left: 0.25vw;
    padding-right: 0.25vw;
    border-radius: 10px;
    font-family: roboto;
    font-weight: 800;
    cursor: pointer;
}
.popup-button:hover{
    background-color: #8FFF00;
}
.alert {
    position: fixed;
    font-family: roboto;
    font-weight: 800;
    bottom: 20px; /* Adjust the distance from the bottom as needed */
    left: 46%;
    transform: translateX(-50%);
    background-color: #B4B4B4;
    padding: 10px 20px; /* Adjust padding as needed */
    border-radius: 30px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    z-index: 999; /* Ensure it appears above other content */
  }
  