.drop-ph{
    background-color: #181717;
    width: 67vw;
    height: 6vh;
    color: white;
    padding: 20px;
    border-radius: 8px;
    margin-top: 5px;
    cursor: auto;
}

.ph-text{
    width: 100%;
    height: 1vh;
    margin: 10px;
    background-color: #181717;
    border-radius: 8px;
    position: relative;
    left: -1vw;
    animation-duration: 2s;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
    animation-name: placeholderAnimate;
    background: #f6f7f8; 
    background: linear-gradient(to right, #333333 2%, #B4B4B4 18%, #333333 33%);
}
@keyframes placeholderAnimate {
    0%{ background-position: -650px 0; }
    100%{ background-position: 650px 0; }
}