
::-webkit-scrollbar {
  display: none;
  
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}
.App{
  background-color: #0D0D0D;
  min-height: 100vh;
  height: 100%;
  padding-bottom: 10vh;
  max-width: 100%;
  overflow-x: hidden;
  
}

